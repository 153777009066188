<template>
  <div class="w-screen max-w-sm p-n-2xl xl:flex xl:flex-1 xl:flex-col xl:justify-between xl:w-full xl:max-w-full only-xl:p-n-4xl only-md:p-n-4xl sm:p-n-2xl">
    <div>
      <div class="flex justify-center">
        <div class="headline-medium mb-s24">
          {{$t('drops.confirm_transaction')}}
        </div>
      </div>
      <div class="subheadline-x-medium mb-s40">
        {{
          $t('drops.are_you_sure', {
            amount: numberFormat(usdAmount),
            asset: drop.asset.name,
          })
        }}
      </div>
    </div>

    <div class="flex justify-between mt-n-4xl">
      <ButtonV2
        @onClick="hideModal"
        :inactive="isLoading"
        :label="$t('cancel')"
        testId="btn-cancel"
        size="medium"
        version="secondary"
        wide
      />
  
      <ButtonV2
        @onClick="buyDrop"
        :inactive="isLoading"
        :label="$t('confirm')"
        testId="btn-confirm"
        size="medium"
        class="ml-n-3xl"
        wide
      />
    </div>
  </div>
</template>

<script>
import ButtonV2 from '@/stories/misc/ButtonV2';
import DROP_BUY from '@/graphql/mutations/DropBuy.gql';
import DropQuery from '@/graphql/queries/Drop.gql';

export default {
  name: 'BuyDropConfirmation',
  components: {
    ButtonV2,
  },

  data() {
    return {
      isLoading: false,
    };
  },

  props: {
    drop: Object,
    specs: Array,
    usdAmount: {
      type: [ String, Number ],
      required: true,
    },
  },

  computed: {
    assetSpecs() {
      return this.specs.reduce((accum, spec) => ({
        ...accum,
        [`asset_${spec.key}`]: spec.value
      }), {});
    },
    dropEventData() {
      return {
        ...this.assetSpecs,
        id: this.drop.id,
        id_hr: this.drop.id_hr,
        running: this.drop.running,
        drop_type: this.drop.type,
        start_time: this.drop.start_time,
        end_time: this.drop.end_time,
        asset_value_usd: this.drop.asset_value_usd,
        asset_id: this.drop.asset.id,
        asset_ticker: this.drop.asset.ticker,
        max_amount: this.usdAmount,
        player_id: this.drop.asset.associated_players[0].id,
        player_id_hr: this.drop.asset.associated_players[0].id_hr,
      };
    }
  },

  methods: {
    async buyDrop() {
      this.isLoading = true;
      try {
        await this.$store.dispatch('events/track', {
          event: 'ASSET_DROP_CHECKOUT_SUBMITTED',
          variables: {
            ...this.dropEventData,
          },
        });

        await this.$apollo.mutate({
          mutation: DROP_BUY,
          variables: {
            drop_id: parseInt(this.$route.params.drop_id),
            supply_amount: this.$big(this.usdAmount),
          }
        });
        
        await this.$store.dispatch('api/refreshPageData', {
          $apollo: this.$apollo,
          query: DropQuery,
          variables: {
            [this.$route.meta.variable]: isNaN(this.$route.params[this.$route.meta.variable]) ? this.$route.params[this.$route.meta.variable] : parseFloat(this.$route.params[this.$route.meta.variable]),
          },
        });

        this.showModal('DropConfirmed', {
          drop: this.drop,
          dropEventData: this.dropEventData,
          dropName: this.dropName,
          playerName: this.playerName,
          usdAmount: this.usdAmount,
        });
      } catch (err) {
        this.showError(err);
      } finally {
        this.isLoading = false;
      }
    },
  },

  async mounted() {
    await this.$store.dispatch('events/track', {
      event: 'ASSET_DROP_CHECKOUT_VIEWED',
      variables: {
        ...this.dropEventData,
      },
    });
  },
};
</script>

<style scoped>
</style>
